<template>
  <div id="main">
    <div id="head">
      <div class="header-logo">
        <img src="../../assets/images/logo.png" />
      </div>

      <div class="title">冷弯联盟管理平台</div>
    </div>

    <div class="wrap" id="wrap">
      <div class="logGet">
        <!-- 头部提示信息 -->
        <div class="logDtip">
          <p class="p1">登录</p>
        </div>
        <div class="lgD">
          <el-form :model="form" label-position="top" label-width="60px">
            <el-form-item label="用户名">
              <el-input v-model="form.username" prefix-icon="el-icon-search"></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="form.password" type="password" @keyup.enter.native="login" prefix-icon="el-icon-lock"> </el-input>
            </el-form-item>

            <el-form-item align="center" class="loginbutton">
              <el-button type="primary" @click="login" class="logC">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="footer">Copyright © 清原满族自治县建新久哲建筑设备租赁有限公司 | <a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备20000950号</a></div>
  </div>
</template>

<script>
import utils from "../../assets/js/utils"
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        version: "1.2"
      },
      //系统管理员菜单
      asideMenu1: [
        {
          path: "/",
          name: "home",
          label: "首页",
          icon: "s-home",
          url: "Home/Home"
        },
        {
          path: "/contract_manage",
          name: "contract_manage",
          label: "合同管理",
          icon: "document",
          url: "ContractManage/ContractManage"
        },
        {
          path: "/collection",
          name: "collection",
          label: "回款明细管理",
          icon: "files",
          url: "CollectionManage/CollectionManage"
        },
        {
          path: "/financial_collection",
          name: "financial_collection",
          label: "财务回款",
          icon: "setting",
          url: "FinancialCollection/FinancialManage"
        },
        {
          path: "/additional_contract",
          name: "additional_contract",
          label: "合同外追加",
          icon: "setting",
          url: "AdditionalContract/AdditionalContractManage"
        }
      ],
      asideMenu2: [
        {
          path: "/",
          name: "home",
          label: "首页",
          icon: "s-home",
          url: "Home/Home"
        },
        {
          path: "/contract_manage",
          name: "contract_manage",
          label: "合同管理",
          icon: "document",
          url: "ContractManage/ContractManage"
        }
      ],
      wuzimanage_zonggongsi: {
        label: "物资管理",
        icon: "setting",
        children: [
          {
            path: "/chuzuheyue",
            name: "chuzuheyue",
            label: "材料通知单",
            icon: "setting",
            url: "WuziManage/contractInfoManage/mainContractManage"
          },
          {
            path: "/diaobodan",
            name: "diaobodan",
            label: "调拨单",
            icon: "setting",
            url: "WuziManage/diaobodan/mainDiaobodan"
          },
          {
            path: "/yikudiaobodan",
            name: "yikudiaobodan",
            label: "移库调拨单",
            icon: "setting",
            url: "WuziManage/yikudiaobodan/mainYikudiaobodan"
          },
          {
            path: "/jiebantongzhidan",
            name: "jiebantongzhidan",
            label: "借板材料通知单",
            icon: "setting",
            url: "WuziManage/jiebantongzhidan/mainJiebanContract"
          },
          {
            path: "/chuku",
            name: "chuku",
            label: "出库信息管理",
            icon: "setting",
            url: "WuziManage/wuziChukuManage/mainChukuManage"
          },
          {
            path: "/huiku",
            name: "huiku",
            label: "回库信息管理",
            icon: "setting",
            url: "WuziManage/wuziHuikuManage/mainHuikuManage"
          },
          {
            path: "/weixiu",
            name: "weixiu",
            label: "物资维修管理",
            icon: "setting",
            url: "WuziManage/weixiuManage/mainWeixiuManage"
          },
          {
            path: "/sunhao",
            name: "sunhao",
            label: "损耗信息管理",
            icon: "setting",
            url: "WuziManage/wuziSunhaoManage/mainSunhaoManage"
          },
          {
            path: "/caigou",
            name: "caigou",
            label: "采购信息管理",
            icon: "setting",
            url: "WuziManage/caigouManage/caigouMain.vue"
          },
          {
            path: "/yiku",
            name: "yiku",
            label: "材料移库管理",
            icon: "setting",
            url: "WuziManage/wuziyiku/yikuMain"
          },
          {
            path: "/pandian",
            name: "pandian",
            label: "在库材料盘点",
            icon: "setting",
            url: "WuziManage/zaikuPandianManage/zaikupandianMain.vue"
          }
        ]
      },
      wuzimanage_fengongsi: {
        label: "物资管理",
        icon: "setting",
        children: [
          {
            path: "/chuzuheyue",
            name: "chuzuheyue",
            label: "材料通知单",
            icon: "setting",
            url: "WuziManage/contractInfoManage/mainContractManage"
          },
          {
            path: "/diaobodan",
            name: "diaobodan",
            label: "调拨单",
            icon: "setting",
            url: "WuziManage/diaobodan/mainDiaobodan"
          },
          {
            path: "/yikudiaobodan",
            name: "yikudiaobodan",
            label: "移库调拨单",
            icon: "setting",
            url: "WuziManage/yikudiaobodan/mainYikudiaobodan"
          },
          {
            path: "/jiebantongzhidan",
            name: "jiebantongzhidan",
            label: "借板材料通知单",
            icon: "setting",
            url: "WuziManage/jiebantongzhidan/mainJiebanContract"
          },
          {
            path: "/chuku",
            name: "chuku",
            label: "出库信息管理",
            icon: "setting",
            url: "WuziManage/wuziChukuManage/mainChukuManage"
          },
          {
            path: "/huiku",
            name: "huiku",
            label: "回库信息管理",
            icon: "setting",
            url: "WuziManage/wuziHuikuManage/mainHuikuManage"
          },
          {
            path: "/weixiu",
            name: "weixiu",
            label: "物资维修管理",
            icon: "setting",
            url: "WuziManage/weixiuManage/mainWeixiuManage"
          },
          {
            path: "/sunhao",
            name: "sunhao",
            label: "损耗信息管理",
            icon: "setting",
            url: "WuziManage/wuziSunhaoManage/mainSunhaoManage"
          },
          {
            path: "/caigou",
            name: "caigou",
            label: "采购信息管理",
            icon: "setting",
            url: "WuziManage/caigouManage/caigouMain.vue"
          },

          {
            path: "/yiku",
            name: "yiku",
            label: "材料移库管理",
            icon: "setting",
            url: "WuziManage/wuziyiku/yikuMain"
          },
          {
            path: "/pandian",
            name: "pandian",
            label: "在库材料盘点",
            icon: "setting",
            url: "WuziManage/zaikuPandianManage/zaikupandianMain.vue"
          }
        ]
      },
      cailiaobaobiao_zonggongsi: {
        label: "材料库存报表",
        icon: "setting",
        children: [
          {
            path: "/kucuntj",
            name: "kucuntj",
            label: "库房材料变化明细表",
            icon: "setting",
            url: "WuziManage/Cailiaobaobiao/KucunTable"
          },
          {
            path: "/ingongditj",
            name: "ingongditj",
            label: "在工地报表",
            icon: "setting",
            url: "WuziManage/Cailiaobaobiao/IngongdiTable"
          },
          {
            path: "/HtingongdiTable",
            name: "HtingongdiTable",
            label: "分公司工地材料报表",
            icon: "setting",
            url: "WuziManage/Cailiaobaobiao/HtingongdiTable"
          },
          {
            path: "/kucunlist",
            name: "kucunlist",
            label: "分公司库存情况报表",
            icon: "setting",
            url: "WuziManage/Cailiaobaobiao/KucunList"
          }
        ]
      },
      cailiaobaobiao_fengongsi: {
        label: "材料库存报表",
        icon: "setting",
        children: [
          {
            path: "/kucunlist",
            name: "kucunlist",
            label: "分公司库存情况报表",
            icon: "setting",
            url: "WuziManage/Cailiaobaobiao/KucunList"
          },
          {
            path: "/HtingongdiTable",
            name: "HtingongdiTable",
            label: "分公司工地材料报表",
            icon: "setting",
            url: "WuziManage/Cailiaobaobiao/HtingongdiTable"
          }
        ]
      },
      cailiaobaobiao_heyue: {
        label: "材料库存报表",
        icon: "setting",
        children: [
          {
            path: "/HtingongdiTable",
            name: "HtingongdiTable",
            label: "分公司工地材料报表",
            icon: "setting",
            url: "WuziManage/Cailiaobaobiao/HtingongdiTable"
          }
        ]
      },
      fawu_manage: {
        label: "法务管理",
        icon: "setting",
        children: [
          {
            path: "/HtFawuManage",
            name: "HtFawuManage",
            label: "法务合同",
            icon: "setting",
            url: "FawuHtManage/HtFawuManageContracts"
          },
          {
            path: "/ZxFawuManage",
            name: "ZxFawuManage",
            label: "法务执行",
            icon: "setting",
            url: "FawuZxManage/ZxFawuManageContracts"
          }
        ]
      },
      asideMenu3: [
        {
          path: "/",
          name: "home",
          label: "首页",
          icon: "s-home",
          url: "Home/Home"
        }
      ]
    }
  },
  methods: {
    login() {
      console.log("this..... ", this)
      this.$axios.post("/login/", this.form).then(res => {
        res = res.data
        console.log("response", res)
        if (res.code === 1000) {
          this.$store.commit("clearMenu")
          let usertype = res.user.user_type.code
          let authority = res.user.authority
          console.log("usertype_id:", usertype)
          if (usertype === "1") {
            //超级管理员
            this.asideMenu1.push(this.wuzimanage_zonggongsi)
            this.asideMenu1.push(this.cailiaobaobiao_zonggongsi)
            this.$store.commit("setMenu", this.asideMenu1)
          } else if (usertype === "2" || usertype === "6") {
            //分公司人员
            this.asideMenu2.push(this.wuzimanage_fengongsi)
            this.asideMenu2.push(this.cailiaobaobiao_fengongsi)
            this.$store.commit("setMenu", this.asideMenu2)
          } else if (usertype === "5") {
            //公司合约部门
            this.asideMenu2.push(this.wuzimanage_fengongsi)
            this.asideMenu2.push(this.cailiaobaobiao_heyue)
            this.$store.commit("setMenu", this.asideMenu2)
          } else if (usertype === "3") {
            //公司法务
            this.asideMenu2.push(this.wuzimanage_zonggongsi)
            this.asideMenu2.push(this.cailiaobaobiao_zonggongsi)
            if (authority === "5") {
              this.asideMenu2.push(this.fawu_manage)
            }
            this.$store.commit("setMenu", this.asideMenu2)
          } else {
            //总公司人员
            this.asideMenu2.push(this.wuzimanage_zonggongsi)
            this.asideMenu2.push(this.cailiaobaobiao_zonggongsi)
            this.$store.commit("setMenu", this.asideMenu2)
          }
          utils.setToken(res.token)
          utils.setUserCookie(res.user)
          utils.setCompanyCookie(res.user.company.code)
          utils.setKeywordCookie("")

          // //存储用户数据到本地session
          // sessionStorage.setItem('user', JSON.stringify(res.user))
          this.$store.commit("addMenu", this.$router)
          this.$router.push({ name: "home" })
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  }
  // created() {
  //   window.addEventListener('keyup', e => {
  //     if (e.keyCode === 13) {
  //       this.login()
  //     }
  //   })
  // }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

body {
  background: #fbfbfb;
}

#main {
  height: 640px;
  background: #fbfbfb;
  width: 1100px;
  margin: 0 auto;
}

#head {
  background: #fbfbfb;
  padding: 0 40px;
  height: 100px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.header-logo {
  top: 50px;
  left: 40px;
  width: 144px;
  position: absolute;
}

.title {
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  width: 200px;
  display: block;
  position: absolute;
  top: 50px;
  left: 200px;
  border-left: 1px solid #ccc;
  color: #888;
}

#wrap {
  height: 640px;
  min-width: 1100px;
  margin: 0 auto;
  background-color: rgb(169, 187, 189);
  background-image: url(../../assets/images/bg4.jpeg);
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow: hidden;
}

.logGet {
  height: 408px;
  width: 368px;
  position: relative;
  background-color: #ffffff;
  top: 20%;
  margin-right: auto;
  margin-left: auto;
}

.logDtip {
  width: 86%;
  border-bottom: 1px solid #ee7700;
  margin-bottom: 30px;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
}

.p1 {
  display: inline-block;
  font-size: 28px;
  margin-top: 30px;
  width: 86%;
}

.lgD {
  width: 86%;
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}

.loginbutton {
  height: 100px;
}

.logC {
  margin-top: 15px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  width: 100%;
  height: 45px;
  background-color: #ee7700;
  border: none;
  color: white;
  color: #000;

  font-size: 18px;
}

.footer {
  font-size: 12px;
  font-family: "Microsoft Yahei", verdana;
  padding: 0;
  width: 1000px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  line-height: 1.5;
}
</style>
